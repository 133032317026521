import getAnonymousId from "@mkt/utils/getAnonymousId"
import trackingEvents from "@mkt/analytics/redline/trackEvents"
import { getCookie, setCookie } from "@mkt/utils/cookie"

export const trackCookies = () => {
  if (!getCookie("start_source_url")) {
    setCookie({
      items: [{ name: "start_source_url", value: window.location.href }],
    })
  }

  const cookieInterval = setInterval(() => {
    const anonymous_id = getAnonymousId()

    if (anonymous_id) {
      trackingEvents.cookies(anonymous_id)
      clearInterval(cookieInterval)
    }
  }, 500)
}
