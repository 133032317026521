import isClient from "./isClient"

const DOMAIN = isClient() ? document.domain : ""

export const setCookie = (data) => {
  if (!isClient()) return

  const { expires, items } = data

  const date = new Date()

  date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000)

  items.forEach((item) => {
    document.cookie = `${item.name}=${item.value}; expires=${date}; path=/;domain=${DOMAIN}`
  })
}

export const getCookie = (sKey) => {
  if (!sKey) {
    return null
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          "(?:(?:^|.*;)\\s*" +
            encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") +
            "\\s*\\=\\s*([^;]*).*$)|^.*$"
        ),
        "$1"
      )
    ) || null
  )
}

export const getAllCookies = () => {
  if (!isClient()) return

  const cookie = document.cookie
  let items = cookie.split(";").reduce((initialState, currentFlag) => {
    if (currentFlag) {
      let currentKey = currentFlag.split("=")[0].trim()
      let currentValue = currentFlag.split("=")

      if (currentValue.length > 2) {
        currentValue =
          currentFlag.split("=")[1] + "=" + currentFlag.split("=")[2]
      } else {
        currentValue = currentFlag.split("=").pop()
      }

      if (currentKey) {
        initialState[currentKey] = currentValue
      }
    }

    return initialState
  }, {})

  let cookieSize = Object.keys(items)

  return cookieSize.length > 0 ? items : {}
}
