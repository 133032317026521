import { track } from "../../../redline"

export const addOnAccessTracker = ({ product, productsList, list }) => {
	const accessTrackedElms = document.querySelectorAll('[data-onaccess]')

	Array.from(accessTrackedElms).map((trackedElm) => {
		const { onaccess } = trackedElm.dataset
		const payload = productsList
			? {
				products: productsList,
				listId: list.id,
				listName: list.name
			  }
			: { products: [product] }

		track(onaccess, payload)

	})
}
