import PropTypes from "prop-types"

import { AnalyticsContext } from "@mkt/utils/contexts"
import useAnalyticsProvider from "./hooks"

const AnalyticsProvider = ({
  children,
  partnerName,
  experienceType,
  pageContent,
  ...props
}) => {
  const trackItems = useAnalyticsProvider({
    partnerName,
    experienceType,
    pageContent,
  })

  return (
    <AnalyticsContext.Provider value={trackItems} {...props}>
      {children}
    </AnalyticsContext.Provider>
  )
}

AnalyticsProvider.propTypes = {
  partnerName: PropTypes.string.isRequired,
  experienceType: PropTypes.string.isRequired,
  pageContent: PropTypes.object,
}

export default AnalyticsProvider
