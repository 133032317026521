import { GTM_KEY } from "gatsby-env-variables"

export default function startGTM () {
	return new Promise ((resolve) => {
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer', GTM_KEY);

		const body = document.querySelector('body')
		const noscript = document.createElement('noscript')
		const iframe = document.createElement('iframe')

		iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${GTM_KEY}`)
		iframe.setAttribute('width', '0')
		iframe.setAttribute('height', '0')
		iframe.setAttribute('style', 'display:none;visibility:hidden')

		noscript.append(iframe)
		
		body.append(noscript)

		resolve ()
	})
}
