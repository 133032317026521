import getSpecificCookie from './getSpecificCookie'

const getTtqCookies = () => {
  const ttqCookie = getSpecificCookie('_ttp')
  const ttqId = ttqCookie ? ttqCookie.split('=')[1] : null

  return { ttqId }
}

export default getTtqCookies
