import getSpecificCookie from "./getSpecificCookie"

const getGACookies = () => {
  const gaCookie = getSpecificCookie("_ga")
  const gaId = gaCookie ? gaCookie.split("=")[1] : null

  return { gaId }
}

export default getGACookies
