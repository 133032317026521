import untilExists from "@mkt/utils/untilExists";

/**
 * @param {string} eventName 
 * @param {Record<string, any>} payload 
 */
export default async function rlTrack(eventName, payload){
    await untilExists(() =>  window?.cpRedline?.track);

    if(window?.cpRedline?.track){
        return window?.cpRedline?.track(eventName, payload)
    }
}