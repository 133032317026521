export const redlineEvent = {
  decisionEnriched: "sortingHat.DecisionEnriched.v1",
  decisionRequested: "sortingHat.DecisionRequested.v1",
  decisionOutcomeReceived: "sortingHat.DecisionOutcomeReceived.v1",
  modalViewed: "sortingHat.ModalViewed.v1",
  decisionAccepted: "sortingHat.DecisionAccepted.v1",
  decisionDenied: "sortingHat.DecisionDenied.v1",
  cookieIdentified: "browser.CookieIdentified.v1",
  productClicked: "ecommerce.ProductClicked.v1",
  productListFiltered: "ecommerce.ProductListFiltered.v1",
  productListViewed: "ecommerce.ProductListViewed.v1",
  productViewed: "ecommerce.ProductViewed.v1",
  redirectRequested: "experience.RedirectRequested.v1",
  formViewed: "userTracking.FormViewed.v1",
  formStarted: "userTracking.FormStarted.v1",
  formContinued: "userTracking.FormContinued.v1",
  formErrored: "userTracking.FormErrored.v1",
  formSubmitted: "userTracking.FormSubmitted.v1",
  elementClicked: "userTracking.ElementClicked.v1",
  proposalStarted: "experience.ProposalStarted.v1",
}

export const categories = {
  creditCard: "credit-card",
  creditCards: "credit-cards",
  captureName: "Inline",
  captureType: "Custom Form",
  idResquest: true,
  stepName: "soft-lead-only-step",
  stepNumber: 0,
  stepType: "CONTACT",
}

export const fbId = "1443350669185912"

export const maxAgeInSeconds = 5 * 60

// maps the track function names to constant variables (track functions are defined in services/trackEvents.js file)
export const trackingEventNames = {
  FORM_STARTED: "formStarted",
  FORM_COMPLETED: "formSubmitted",
  FORM_STARTED_FORM_FILL: "proposalStartedFormFill",
  FORM_VIEWED: "formViewed",

  REDIRECT_REQUESTED: "redirectRequested",

  REFERRAL_SENT: "referralSent",
  REFERRAL_SENT_NN: "referralSentNN",

  PRODUCT_CLICKED: "productClicked",
  PRODUCT_VIEWED: "productViewed",

  PRODUCT_LIST_FILTERED: "productListFiltered",
  PRODUCT_LIST_VIEWED: "productListViewed",
}

export const keysPixel = {
  azulis: "1909183302519196",
  ccbr: "1443350669185912",
  iq: "328658652481268",
  cp: "328658652481268",
}

export const pixelForbiddenKeywords = [
  "name",
  "email",
  "cpf",
  "phone",
  "bank_data",
  "account_data",
  "id_data",
  "date_of_birth",
]

export const ttqValidEvents = [
  "AddPaymentInfo",
  "AddToCart",
  "AddToWishlist",
  "ClickButton",
  "CompletePayment",
  "CompleteRegistration",
  "Contact",
  "Download",
  "InitiateCheckout",
  "PlaceAnOrder",
  "Search",
  "SubmitForm",
  "Subscribe",
  "ViewContent",
]

export const ttqEventsWithPayload = [
  "AddToCart",
  "CompletePayment",
  "PlaceAnOrder",
  "ViewContent",
]
