/* globals APP_NAME */
import { keysPixel, pixelForbiddenKeywords } from "@mkt/analytics/constants"

const getCurrencyNumber = ({fieldId, fieldValue, fieldType}) => {
  const isCurrencyValue = fieldType === 'Currency' || fieldType === 'br-currency'

  if(!isCurrencyValue) {
    return {}
  }

  const valueDecimalsWithPoint = fieldValue.replace(/\./g, '').replace(',', '.')
  const valueNumbersAndDecimals = valueDecimalsWithPoint.replace(/[^\d\.]/g, "")
  const valueFloat = parseFloat(valueNumbersAndDecimals)

  return { [`${fieldId}_number`]: valueFloat }
}

const generateFlatPayload = (payload, separator = ".", sub) => {
  return payload === null
    ? payload
    : Object.keys(payload).reduce((flatPayload, key) => {
        const value = payload[key]
        const subKey = sub ? `${sub}${separator}${key}` : key

        if (typeof value === 'object') {
          const subPayload = generateFlatPayload(value, separator, subKey)
          return { ...subPayload, ...flatPayload }
        } else {
          return {
            ...flatPayload,
            [subKey]: value,
          }
        }
      }, {})
}

const setFieldsObject = (propertiesFields) =>
  propertiesFields?.reduce((newFields, field) => {
    const { fieldId, fieldValue, fieldType } = field

    const hasForbiddenKeyword = !!pixelForbiddenKeywords.find((keyword) =>
      fieldId.toUpperCase().includes(keyword.toUpperCase())
    )

    const currencyNumber = getCurrencyNumber({fieldId, fieldValue, fieldType})
    
    return hasForbiddenKeyword
      ? newFields
      : { ...newFields, [fieldId]: fieldValue, ...currencyNumber }
  }, {})

export const generatePixelPayload = ({ name, properties = {}, contentType, context = {}, separator }) => {
  const fbEventName = {
    formViewed: 'ViewContent',
    formSubmitted: 'AddToCart',
  }

  const isSpecialEvent = Object.keys(fbEventName).includes(name)

  const getSpecialEventProp = (prop) => {
    return isSpecialEvent ? prop : null
  }

  const contentIds = properties?.captureContext?.valueProp
  const content_type = getSpecialEventProp(contentType)
  const content_ids = getSpecialEventProp(contentIds)

  const fields = setFieldsObject(properties?.field)

  const newProperties = {
    ...properties,
    field: fields,
  }

  const flatPayload = generateFlatPayload({
    properties: newProperties,
    context,
  }, 
  separator
  )

  return { ...flatPayload, content_type, content_ids }
}

export const pixel = (name, properties = {}, contentType, context = {}) => {
  const fbEventName = {
    formViewed: 'ViewContent',
    formSubmitted: 'AddToCart',
  }

  const isSpecialEvent = Object.keys(fbEventName).includes(name)

  const eventPayload = generatePixelPayload({ name, properties, contentType, context })

  if (typeof fbq !== 'undefined') {
    if (isSpecialEvent) {
      //eslint-disable-next-line
      fbq('trackSingleCustom', keysPixel[APP_NAME], fbEventName[name], eventPayload)
    }
    //eslint-disable-next-line
    fbq('trackSingleCustom', keysPixel[APP_NAME], name, eventPayload)
  }
}