import getElementCoords from "@mkt/utils/getElementCoords"
import isButton from "@mkt/utils/isButton"
import isLink from "@mkt/utils/isLink"

const shouldBeIgnored = (target) => {
  const trackingIgnore = target.dataset.ignoreAnalytics
  return trackingIgnore
}

export const searchDataClicked = ({ element, counter = 0 }) => {

  if (element === document) return

  const isClickableElement = isLink(element) || isButton(element)
  const parentLink = element.closest('a')
  const parentButton = element.closest('button')
  const clickableElm = isClickableElement ? element : parentLink ? parentLink : parentButton

  const isElementClicked =
  (clickableElm?.hasAttribute && clickableElm?.hasAttribute("data-clicked"))

  if (!isElementClicked && !isClickableElement && !clickableElm) return
  const elementShouldBeIgnored = shouldBeIgnored(clickableElm)

 if (!!clickableElm && !isElementClicked && !elementShouldBeIgnored) {
    clickableElm.setAttribute("data-clicked", "")
    clickableElm.setAttribute("id", clickableElm.textContent)
    return clickableElm
  }

  if (isElementClicked) {
    return clickableElm
  } else if (counter < 5) {
    const parentNode = clickableElm.parentNode
    return searchDataClicked({ element: parentNode, counter: counter + 1 })
  } else {
    return false
  }
}

export const getAnalyticsData = (target) => {

  if (target) {
    const parentNode = target.parentNode
    return {
      element_id: target?.dataset?.clicked || parentNode?.dataset?.clicked,
      element_text:
        target?.textContent || target?.innerHTML || parentNode?.textContent,
      element_url: window.location.href,
      element_type: target?.tagName || "",
      element_position: getElementCoords(target),
    }
  }

  return {}
}
