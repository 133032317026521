import useAnalytics from "@mkt/ui/hooks/useAnalytics"
import trackEvents from "./trackEvents"

export default function track(trackEventName, payload, fireboltId) {
  const trackingEvent = trackEvents[trackEventName]

  if (trackingEvent) {
    return trackingEvent(payload, fireboltId)
  }
}

export function useTrack() {
  const { fireboltId } = useAnalytics()

  const trackWithFID = (trackEventName, payload) => {
    return track(trackEventName, payload, fireboltId)
  }
  return {
    track: trackWithFID
  }
}
