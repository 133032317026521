export default function untilExists(action, timeoutTime, intervalTime = 100) {
  return new Promise((res) => {
    let value = action()
    if (value) res(value)
    const interval = setInterval(() => {
      value = action()
      if (value) {
        clearInterval(interval)
        res(value)
      }
    }, intervalTime)

    if (timeoutTime) {
      const timeout = setTimeout(() => {
        clearInterval(interval)
        res(value)
        clearTimeout(timeout)
      }, timeoutTime)
    }
  })
}
