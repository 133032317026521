import getInstanceId from "@mkt/utils/getInstanceId"
import getSessionIdTglr from "@mkt/utils/getSessionIdTglr"

export default function setInstanceInfo(setInstanceId, setSessionIdTglr) {
  const intervalCheck = setInterval(() => {
    const instanceId = getInstanceId()
    const sessionId = getSessionIdTglr()

    if (!!instanceId) {
      setInstanceId(instanceId)
      setSessionIdTglr(sessionId)
      clearInterval(intervalCheck)
    }
  }, 500)
}
