import getAnonymousId from "@mkt/utils/getAnonymousId"
import getFbCookies from "@mkt/utils/getFBCookies"

export const setFbInfos = (anonymousId, setAnonymousId, setFbCookies) => {
	const intervalCheck = setInterval(() => {
		const localFbCookies = getFbCookies()
		const currAnonymousId = getAnonymousId()
		
		if (!!currAnonymousId && !anonymousId) {
			clearInterval(intervalCheck)
			setAnonymousId(currAnonymousId)
			setFbCookies(localFbCookies)
		}
	}, 500)
}