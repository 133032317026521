import { useEffect, useState, useRef } from "react"
import { createRedline } from "redline-client-sdk"

import { searchDataClicked } from "../helpers"
import { addScrollTracker } from "../helpers/scroll"
import { addOnAccessTracker } from "../helpers/onaccess"
import { startTrackers } from "./starters"
import { setFbInfos } from "./fbInfos"
import { setSessionInfos } from "./sessionInfos"
import { trackClick } from "./click"
import { getDecodedFireboltStore } from "../../../redline/utils"
import getTrackingPartner from "../../../helpers/getTrackingPartner"

import { globalHistory } from "@reach/router"
import setInstanceInfo from "./instanceInfo"
import { parsePageContentAdobe } from "../helpers/adobePayload"
import getGACookies from "@mkt/utils/getGACookies"
import getTtqCookies from "@mkt/utils/getTtqCookies"
import isClient from "@mkt/utils/isClient"

import {
  APP_NAME,
  REDLINE_WRITEKEY,
  REDLINE_APP_NAME,
} from "gatsby-env-variables"

import isDev from "@mkt/utils/isDev"

export default function useAnalyticsProvider({
  partnerName,
  experienceType,
  pageContent,
}) {
  const rl = useRef(
    createRedline({
      writeKey: REDLINE_WRITEKEY,
      mode: isDev ? "dev" : "prod",
      appName: REDLINE_APP_NAME,
    })
  )
  const defaultPayloadData = { partnerName, experienceType, pageContent }
  const [trackersLoaded, setTrackersLoaded] = useState(false)
  const [anonymousId, setAnonymousId] = useState("")
  const [instanceId, setInstanceId] = useState("")
  const [fbCookies, setFbCookies] = useState()
  const [gaCookies, setGaCookies] = useState()
  const [ttqCookies, setTtqCookies] = useState()
  const [sourceUrl, setSourceUrl] = useState()
  const [sessionId, setSessionId] = useState("")
  const [sessionIdTglr, setSessionIdTglr] = useState("")
  const [product, setProduct] = useState(null)
  const [productsList, setProductsList] = useState(null)
  const [list, setList] = useState({})
  const [form, setForm] = useState({})
  const [elementClicked, setElementClicked] = useState(null)
  const [globalError, setGlobalError] = useState(false)
  const [fireboltId, setFireboltId] = useState("")
  const [trackedEvents, setTrackedEvents] = useState([])
  const [isOneTrustEnabled, setIsOneTrustEnabled] = useState([])

  if (APP_NAME === "mastercard" && isClient()) {
    useEffect(() => {
      const checkOneTrustStatus = () => {
        const currentOneTrustStatus = window?.OnetrustActiveGroups
        if (currentOneTrustStatus !== isOneTrustEnabled) {
          setIsOneTrustEnabled(currentOneTrustStatus)
        }
      }

      const intervalId = setInterval(checkOneTrustStatus, 500)
      return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
      if (isOneTrustEnabled?.includes("C0002")) {
        window.di = parsePageContentAdobe({ pageContent })

        startTrackers(trackersLoaded, setTrackersLoaded, rl.current)
        setSessionInfos(setSourceUrl, setSessionId)
        // setInstanceInfo(setInstanceId, setSessionIdTglr)

        return globalHistory.listen(({ action }) => {
          if (action === "PUSH") {
            rl.current.dispatchSPAPathChange()
          }
        })
      }
    }, [isOneTrustEnabled, pageContent])
  }

  useEffect(() => {
    if (APP_NAME !== "mastercard") {
      startTrackers(trackersLoaded, setTrackersLoaded, rl.current)
      setFbInfos(anonymousId, setAnonymousId, setFbCookies)
      setSessionInfos(setSourceUrl, setSessionId)
      setInstanceInfo(setInstanceId, setSessionIdTglr)

      return globalHistory.listen(({ action }) => {
        if (action === "PUSH") {
          rl.current.dispatchSPAPathChange()
        }
      })
    }
  }, [])

  useEffect(() => {
    const maxInterations = 100
    let iteration = 0

    const waitFireboltStore = setInterval(() => {
      const trackingPartner = getTrackingPartner()

      const currentFireboltId =
        getDecodedFireboltStore(trackingPartner)?.unique_id
      const localGaCookies = getGACookies()
      const localTtqCookies = getTtqCookies()
      const iteratedMaxTimes = iteration === maxInterations

      if (currentFireboltId) setFireboltId(currentFireboltId)

      if (localGaCookies?.gaId) setGaCookies(localGaCookies)

      if (localTtqCookies) setTtqCookies(localTtqCookies)

      if (
        (currentFireboltId && localGaCookies?.gaId) ||
        (localGaCookies?.gaId && iteratedMaxTimes) ||
        (currentFireboltId && iteratedMaxTimes)
      )
        clearInterval(waitFireboltStore)

      iteration++
    }, 500)
  }, [])

  useEffect(() => {
    if (elementClicked && trackersLoaded && product) {
      trackClick(elementClicked, product)
      setElementClicked(null)
    }
  }, [product])

  useEffect(() => {
    if (product || productsList) {
      addOnAccessTracker({ product, productsList, list })
      addScrollTracker({
        form,
        product,
        productsList,
        list,
        defaultPayloadData,
      })
    }
  }, [productsList, APP_NAME !== "mastercard" ? product : null])

  function trackClicksOnApp(e = {}) {
    const target = e.target
    const isElementClicked = searchDataClicked({ element: target })
    const targetPosition =
      parseInt(target.parentNode?.dataset?.position) || null

    if (isElementClicked && trackersLoaded) {
      setElementClicked(isElementClicked)

      if (targetPosition) {
        const currentProduct = productsList[targetPosition]
        setProduct({ ...currentProduct, position: targetPosition })
      }
    }
  }

  function dispatchGlobalError() {
    setGlobalError(!globalError)
  }

  return {
    trackersLoaded,
    trackClicksOnApp,
    anonymousId,
    sessionIdTglr,
    fireboltId,
    setFireboltId,
    instanceId,
    fbCookies,
    gaCookies,
    ttqCookies,
    sourceUrl,
    sessionId,
    dispatchGlobalError,
    globalError,
    setGlobalError,
    product: { data: product, setProduct },
    productsList: { data: productsList, setProductsList },
    list: { data: list, setList },
    form: { data: form, setForm },
    trackedEventsState: [trackedEvents, setTrackedEvents],
  }
}
