import { ttqValidEvents, ttqEventsWithPayload } from "@mkt/analytics/constants"

const ttqCreatePayload = ({
  content_type = 'product', 
  quantity = 0,
  description = '',
  content_id = '',
  currency = 'BRL',
  value = 0
}) => {
  
  return ({
    content_type,
    quantity,
    description,
    content_id,
    currency,
    value
  })
}

export const ttqTrackEvent = (name, payload, contentType) => {
  const ttqEventNames = {
    consentCaptured: '',
    decisionAccepted: '',
    decisionDenied: '',
    decisionEnriched: '',
    decisionOutcomeReceived: '',
    decisionRequested: '',
    elementClicked: 'ClickButton',
    elementViewed: 'ViewContent',
    formBacked: '',
    formContinued: '',
    formErrored: '',
    formStarted: 'InitiateCheckout',
    formSubmitted: 'PlaceAnOrder',
    formViewed: 'ViewContent',
    mediaStatusTracked: '',
    modalViewed: 'ViewContent',
    panFGTSSimulationCompleted: 'PlaceAnOrder',
    productClicked: 'ClickButton',
    productListFiltered: 'Search',
    productListViewed: 'ViewContent',
    productViewed: 'ViewContent',
    redirectRequested: ''
  }

  const ttqEvent = ttqEventNames?.[name]

  const hasPayload = ttqEventsWithPayload.includes(ttqEvent)
  const isViewContent = ttqEvent === 'ViewContent'

  const eventPayload = ttqCreatePayload({
    description: isViewContent ? name : '',
    contentType
  })

  if (typeof ttq !== 'undefined' && ttqValidEvents.includes(ttqEvent)) {
    if (hasPayload) {
      ttq.track (ttqEvent, eventPayload)
    } else {
      ttq.track(ttqEvent)
    }
  }
}
