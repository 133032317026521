import getSessionId from "@mkt/utils/getSessionId"
import getSourceUrl from "@mkt/utils/getSourceUrl"

export const setSessionInfos = (setSourceUrl, setSessionId) => {
	const sourceUrlFromBrowser = getSourceUrl()
    setSourceUrl(sourceUrlFromBrowser)

    const sessionIdFromCookies = getSessionId()
    setSessionId(sessionIdFromCookies)
}
