import { getCookie } from "./cookie"
import isClient from "./isClient"

export const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  )
}

const DOMAIN = isClient() ? window.domain : ""

const saveCookie = (variable, content, expirationHours) => {
  const exdate = new Date()
  exdate.setMinutes(exdate.getMinutes() + expirationHours * 60)
  document.cookie = `${variable}=${encodeURIComponent(
    content
  )}; domain=${DOMAIN};path=/;expires=${exdate.toUTCString()}`
}

const getSessionId = () => {
  const sid = getCookie("_sessionId") || guid()
  saveCookie("_sessionId", sid, 0.5)
  return sid
}

export default getSessionId
