import isClient from "@mkt/utils/isClient"

export default function getTrackingPartner() {
  const { host } = isClient() ? window?.location : { host: "" }

  const isIq = (isClient() && host?.includes("8000")) || host?.includes("iq")
  const isCp =
    (isClient() && host?.includes("8004")) ||
    host?.includes("consumidorpositivo")

  const isDigioCards =
    isClient() &&
    window?.location?.href.indexOf("/cartao-de-credito/digio") > -1

  const usingDigioJSON = (isIq || isCp) && isDigioCards

  return usingDigioJSON ? "digio-cards" : "iq-marketplace"
}
