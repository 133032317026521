import AnalyticsProvider from "@mkt/analytics/components/AnalyticsProvider"

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  locations.push(window.location.href)
  window.previousPath = locations[locations.length - 2]
}

export const wrapPageElement = ({ element, props }) => {
  const pageContent = props?.pageContext?.content || {}

  return (
    <AnalyticsProvider partnerName="iq Marketplace" experienceType="credit" pageContent={pageContent} >
      {element}
    </AnalyticsProvider>
  )
}
