export default function getElementCoords(element) {
  if (element && element.getBoundingClientRect) {
    const { top, left, width, height } = element.getBoundingClientRect()
    const points = {
      "top left": {
        x: left + window.pageXOffset,
        y: top + window.pageYOffset,
      },
      "top center": {
        x: left + width / 2 + window.pageXOffset,
        y: top + window.pageYOffset,
      },
      "top right": {
        x: left + width + window.pageXOffset,
        y: top + window.pageYOffset,
      },
      "center left": {
        x: left + window.pageXOffset,
        y: top + height / 2 + window.pageYOffset,
      },
      center: {
        x: left + width / 2 + window.pageXOffset,
        y: top + height / 2 + window.pageYOffset,
      },
      "center right": {
        x: left + width + window.pageXOffset,
        y: top + height / 2 + window.pageYOffset,
      },
      "bottom left": {
        x: left + window.pageXOffset,
        y: top + height + window.pageYOffset,
      },
      "bottom center": {
        x: left + width / 2 + window.pageXOffset,
        y: top + height + window.pageYOffset,
      },
      "bottom right": {
        x: left + width + window.pageXOffset,
        y: top + height + window.pageYOffset,
      },
    }

    return points
  }

  return {}
}
