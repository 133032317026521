import { track } from "@mkt/analytics/redline"
import { getProductData, isInViewport } from "@mkt/analytics/redline/utils"

import throttle from "@mkt/utils/throttle"

export const addScrollTracker = ({form, product, productsList, list, defaultPayloadData, customWindowSize, location}) => {
	const alreadyViewed = {}

	setTimeout(() => {
		const scrollTrackedElms = document.querySelectorAll("[data-scroll]")
		const scrollTrackedModal = document.querySelectorAll("[data-modal-scroll]")

		if (scrollTrackedElms.length > 0) {
			Array.from(scrollTrackedElms).map((trackedElm, key) => {
				const { scroll, tracklistening } = trackedElm.dataset

				const onscroll = throttle(_ => {

					const bottomVisiblePercent = scroll	=== "productViewed" ? 1 : 1.5

					if (isInViewport(trackedElm, bottomVisiblePercent, customWindowSize)) {
						const allProducts = productsList
							? productsList
							: product
								? getProductData(product)
								: []

						const trackProps = {
							listId: list?.id || list?.data?.id || "",
							listName: list?.name || list?.data?.name || "",
							customLocation: location,
							products: allProducts,
							form,
							elm: trackedElm
						}

						track(scroll, { ...defaultPayloadData, ...trackProps })

						alreadyViewed[key] = true
					}

					if (alreadyViewed[key]) {
						window.removeEventListener('scroll', onscroll)
						scrollTrackedModal?.forEach((modal) => modal.removeEventListener('scroll', onscroll))
					}
				}, 200)

				if(!tracklistening) {
					window.addEventListener('scroll', onscroll)
					scrollTrackedModal?.forEach((modal) => modal.addEventListener('scroll', onscroll))
					trackedElm.setAttribute('data-tracklistening', true)
					onscroll()
				}
			})
		}
	}, 300)
}
