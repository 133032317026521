import { getCookie } from "@mkt/utils/cookie"

import { redlineEvent } from "../../constants"
import rlTrack from "./rlTrack"

export {
  formatFormData,
  formatFormValues,
  formatProductsList,
  formatListId,
} from "./formatters"
export {
  getFilters,
  getProductData,
  getProductDataMastercard,
  getDecodedFireboltStore,
  getCorrelationId,
} from "./getters"
export { onClickCustom, onSubmit, onSubmitFilter } from "./handlers"

export const dispatchCookieTracking = (name) => {
  const cookie = getCookie(name)
  if (cookie) {
    rlTrack(redlineEvent.cookieIdentified, {
      event: redlineEvent.cookieIdentified,
      key: name,
      value: cookie,
    })
  }
}

export const clearCookie = (cookie) => {
  setTimeout(() => (document.cookie = `${cookie}=''; max-age=0`), 100)
}

export const isInViewport = (
  element,
  bottomPercent = 1.5,
  customWindowSize = null
) => {
  const rect = element.getBoundingClientRect()
  const windowHeight = customWindowSize
    ? customWindowSize
    : window.innerHeight || document.documentElement.clientHeight
  const isComponentVisible =
    (rect.top <= windowHeight * 0.5 ||
      rect.bottom <= windowHeight * bottomPercent) &&
    rect.width > 0 &&
    rect.height > 0

  return isComponentVisible
}
