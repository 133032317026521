import { APP_NAME } from "gatsby-env-variables"
import { categories, redlineEvent } from "../../constants"
import {
  getCorrelationId,
  getProductData,
  getProductDataMastercard,
} from "./getters"

const clearedPathname = () => {
  const { pathname } = window.location
  return APP_NAME !== "mastercard" ? pathname.replace(/\/$/, "") : pathname
}

export const formatFormData = (data, alternativeProduct) => {
  const { stepName } = categories

  const product = JSON.parse(alternativeProduct)

  const formId = `${data.formId}-${data.formName}`
  const stepId = `${formId}-${stepName}`

  return {
    data: {
      ...data,
      outboundUrl: product.href || product.redirect_url,
      brand: product.issuer || product.card_issuer,
      stepId,
      formId,
    },
    stepId,
  }
}

export const formatFormValues = (fields, stepId, props) => {
  const trackFields = [
    "full_name",
    "email",
    "bad_credit",
    "main_phone",
    "cpf",
    "main_income",
    "has_credit_restriction",
  ]

  return Object.keys(props).reduce((allValues, key) => {
    if (!trackFields.includes(key)) return allValues

    const currentField = fields.find(({ slug }) => slug === key)
    const { label } = currentField ? currentField["ui:props"] : key

    return [
      ...allValues,
      {
        autofilled: false,
        fieldId: `${stepId}-${key}`,
        fieldLabel: label,
        fieldName: key,
        fieldType: currentField ? currentField["ui:widget"] : "",
        fieldValue: props[key].toString(),
        fieldSource: "user-input",
      },
    ]
  }, [])
}

export const formatProductsList = (products, brand) => {
  const productsArr = Array.isArray(products) ? products : [products]
  const brandFunctions = {
    default: getProductData,
    mastercard: getProductDataMastercard,
  }
  const selectedFunction = brandFunctions[brand] || brandFunctions.default

  return productsArr.map((product, position) => {
    return selectedFunction(product, position)
  })
}

export const formatListId = (listId) => {
  const { pathname } = window.location
  const currentPage =
    pathname === "/"
      ? "home"
      : pathname.match(/\/([\w-]+)\/?/)[1].replaceAll("/", "-")

  return `${APP_NAME}-${currentPage}-${listId}`
}

export const formatListIdMatercard = () => {
  const { pathname } = window.location
  const currentPage =
    pathname === "/" ? "home" : pathname.replace(/\/$/, "").split("/").pop()

  return `${APP_NAME}-${currentPage}`
}

export const formatTrackingPayload = (event, form, objectOptions) => {
  const { noContext } = objectOptions || {}
  const { pathname } = window.location
  const slug = pathname === "/" ? "home" : clearedPathname()?.split("/").pop()

  const captureContext = noContext
    ? {}
    : {
        captureContext: {
          capturePlacement: categories.captureName,
          captureType: categories.captureType,
          identityRequested: categories.idResquest,
          valueProp: slug,
        },
      }

  return {
    ...captureContext,
    ...formatTrackingForm(event, form),
  }
}

export const formatTrackingForm = (event, form) => {
  const correlationId = getCorrelationId()

  const { pathname } = window.location
  const slug = pathname === "/" ? "home" : clearedPathname()?.split("/").pop()
  const formId = `${APP_NAME}-${slug}-${form?.name}`

  return {
    "@type": redlineEvent[event],

    correlationId: correlationId,
    formContext: {
      formBrand: form?.brand || form?.issuer,
      formId: formId,
      formName: form?.name,
      formType: form?.type,
      formVersion: form?.version,
    },
    stepContext: {
      stepId: `${formId}-${form?.type}-only-step`,
      stepName: categories.stepName,
      stepNumber: categories.stepNumber,
      stepType: categories.stepType,
    },
  }
}
