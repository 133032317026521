import { trackingEventNames } from "@mkt/analytics/constants"
import { track } from "@mkt/analytics/redline"
import { getAnalyticsData } from "../helpers"

export const trackClick = (elementClicked, product) => {
	const customData = elementClicked.dataset.custom

	track(trackingEventNames["PRODUCT_CLICKED"], {
		...getAnalyticsData(elementClicked),
		customData,
		product
	})

}
