import { categories, redlineEvent } from "../constants"
import triggerTrackers from "./triggerTrackers"
import { APP_NAME } from "gatsby-env-variables"

import {
  dispatchCookieTracking,
  getProductData,
  getProductDataMastercard,
  clearCookie,
  getCorrelationId,
} from "./utils"
import {
  formatListId,
  formatListIdMatercard,
  formatTrackingPayload,
  formatFormValues,
  formatProductsList,
} from "./utils/formatters"
import rlTrack from "./utils/rlTrack"
import { getCookie } from "@mkt/utils/cookie"

const trackEvents = {
  cookies: (anonymousId) => {
    const cookies = ["_fbc", "_fbp", "_ga"]
    cookies.map((cookie) => dispatchCookieTracking(cookie))

    return rlTrack(redlineEvent.cookieIdentified, {
      event: redlineEvent.cookieIdentified,
      key: "anonymous_id",
      value: anonymousId,
    })
  },

  productClicked: ({ product, ...data }) => {
    const { slug, listId, productId } = product
    const { origin } = window.location

    const fullOutboundUrl = slug
      ? slug?.includes("http")
        ? slug
        : `${origin}/${slug}`
      : `${origin}/${productId}`

    const actionOutcome = fullOutboundUrl?.includes(origin)
      ? "internal-link"
      : "external-link"

    const productData = getProductData({ ...product, ...data })
    const productDataMastercard = getProductDataMastercard({
      ...product,
      ...data,
    })
    const correlationId = getCorrelationId()
    const fullListId = listId ? formatListId(listId) : null
    const fullListIdMaster = listId ? formatListIdMatercard(listId) : null

    const payload = {
      default: {
        actionOutcome: actionOutcome,
        correlationId: correlationId,
        listId: fullListId,
        outboundUrl: fullOutboundUrl,
        product: {
          ...productData,
          location: productData.pageId || "",
          position: product.position,
        },
      },
      mastercard: {
        listId: fullListIdMaster,
        product: {
          ...productDataMastercard,
          location: fullListIdMaster || "",
          category: categories.creditCards,
          position: product.position,
        },
      },
    }

    const finalPayload =
      APP_NAME === "default" || !payload[APP_NAME]
        ? payload.default
        : { ...payload.default, ...payload[APP_NAME] }

    clearCookie("correlationId")
    return triggerTrackers({
      event: "productClicked",
      payload: finalPayload,
    })
  },

  productListFiltered: ({ filters, list, products }) => {
    const formattedFilters = Object.keys(filters).reduce((allFilters, key) => {
      return [
        ...allFilters,
        {
          type: key,
          value: filters[key],
        },
      ]
    }, [])

    const fullListId = formatListId(list)
    const correlationId = getCorrelationId()

    const payload = {
      listId: fullListId,
      product: formatProductsList(products),
      viewCorrelationId: correlationId,
      filters: formattedFilters,
    }

    return triggerTrackers({ event: "productListFiltered", payload })
  },

  productListViewed: ({ listId, listName, products }) => {
    const fullListId = formatListId(listId)

    const correlationId = getCorrelationId()

    const payload = {
      category: categories.creditCards,
      correlationId: correlationId,
      listId: fullListId,
      listName: listName,
      product: formatProductsList(products),
    }

    return triggerTrackers({ event: "productListViewed", payload })
  },

  productListViewedMastercard: ({ listId, listName, products }) => {
    const fullListId = formatListIdMatercard(listId)

    const correlationId = getCorrelationId()

    const payload = {
      category: categories.creditCards,
      correlationId: correlationId,
      listId: fullListId,
      listName: listName,
      product: formatProductsList(products, "mastercard"),
    }

    return triggerTrackers({ event: "productListViewed", payload })
  },

  productViewed: ({ products, listId, elm, customLocation }) => {
    const position = elm ? elm.dataset.position : 0
    const product = products[position]
    const productData = getProductData(product, position)
    const fullListId = customLocation
      ? customLocation
      : listId
      ? formatListId(listId)
      : null
    const correlationId = getCorrelationId()

    const payload = {
      listId: fullListId,
      product: {
        ...productData,
        location: fullListId || "",
      },
      viewCorrelationId: correlationId,
    }

    return triggerTrackers({ event: "productViewed", payload })
  },

  productViewedMastercard: ({ products, listId, elm, customLocation }) => {
    const position = elm ? elm.dataset.position : 0
    const product = products[position]
    const productData = getProductDataMastercard(product, position)
    const fullListId = customLocation
      ? customLocation
      : listId
      ? formatListIdMatercard(listId)
      : null
    const correlationId = getCorrelationId()

    const payload = {
      listId: fullListId,
      product: {
        ...productData,
        location: fullListId || "",
      },
      viewCorrelationId: correlationId,
    }

    return triggerTrackers({ event: "productViewed", payload })
  },

  formViewed: ({ form }) => {
    const payload = formatTrackingPayload("formViewed", form)

    return triggerTrackers({ event: "formViewed", payload })
  },

  elementClicked: (payload) => {
    return triggerTrackers({ event: "elementClicked", payload })
  },

  formStarted: (form) => {
    const payload = formatTrackingPayload("formStarted", form)

    return triggerTrackers({ event: "formStarted", payload })
  },

  formContinued: (form) => {
    const payload = formatTrackingPayload("formContinued", form)

    return triggerTrackers({ event: "formContinued", payload })
  },

  formErrored: (form) => {
    const payload = formatTrackingPayload("formErrored", form)

    const errorType = form?.errors?.[0]?.validationResults?.[0]?.message

    return triggerTrackers({
      event: "formErrored",
      payload: { ...payload, errorType },
    })
  },

  formSubmitted: (props) => {
    const { fields, slug } = props
    const values = formatFormValues(fields, slug, props)

    const payload = {
      ...formatTrackingPayload("formSubmitted", props),
      field: values,
    }

    clearCookie("formCorrelationId")
    return triggerTrackers({ event: "formSubmitted", payload })
  },

  redirectRequested: (
    { redirectSlug, originSlug, redirectUrl, fireboltId },
    contextFID
  ) => {
    const payload = {
      originId: originSlug,
      redirectRequestedId: redirectSlug,
      redirectRequestedUrl: redirectUrl,
      fireboltId: !!fireboltId ? fireboltId : contextFID,
    }
    return triggerTrackers({ event: "redirectRequested", payload })
  },

  decisionRequested: ({ partner, cardSlug, shVersion, workflowId }) => {
    const correlationId = getCorrelationId()
    const sortingHatVersion = shVersion || "v3"
    const redlinePayload = {
      correlationId,
      sortingHatVersion,
      partner,
      cardSlug,
      workflowId,
    }

    return rlTrack(redlineEvent.decisionRequested, {
      event: redlineEvent.decisionRequested,
      ...redlinePayload,
    })
  },

  decisionOutcomeReceived: ({
    partner,
    cardSlug,
    decisionProducts,
    shVersion,
    enabled,
    workflowId,
  }) => {
    const correlationId = getCorrelationId()
    const sortingHatVersion = shVersion || "v3"
    const redlinePayload = {
      correlationId,
      sortingHatVersion,
      partner,
      cardSlug,
      decisionProducts,
      enabled,
      workflowId,
    }

    return rlTrack(redlineEvent.decisionOutcomeReceived, {
      event: redlineEvent.decisionOutcomeReceived,
      ...redlinePayload,
    })
  },

  decisionEnriched: ({ partner, cardSlug }) => {
    const correlationId = getCorrelationId()
    const redlinePayload = {
      correlationId,
      partner,
      cardSlug,
    }

    return rlTrack(redlineEvent.decisionEnriched, {
      event: redlineEvent.decisionEnriched,
      ...redlinePayload,
    })
  },
  modalViewed: ({ suggestionContext, current }) => {
    const correlationId = getCorrelationId()
    const redlinePayload = {
      correlationId,
      sortingHatVersion: current.shVersion || "v3",
      currentCardPartner: current.partner,
      currentCardSlug: current.cardSlug,
      suggestionContext,
    }

    return rlTrack(redlineEvent.modalViewed, {
      event: redlineEvent.modalViewed,
      ...redlinePayload,
    })
  },

  decisionAccepted: ({ accepted, shVersion, correlationId, workflowId }) => {
    const redlinePayload = {
      correlationId,
      sortingHatVersion: shVersion || "v3",
      accepted,
      workflowId,
    }

    clearCookie("correlationId")
    return rlTrack(redlineEvent.decisionAccepted, {
      event: redlineEvent.decisionAccepted,
      ...redlinePayload,
    })
  },

  decisionDenied: ({ accepted, shVersion, correlationId, workflowId }) => {
    const redlinePayload = {
      correlationId,
      sortingHatVersion: shVersion || "v3",
      accepted,
      workflowId,
    }

    clearCookie("correlationId")
    return rlTrack(redlineEvent.decisionDenied, {
      event: redlineEvent.decisionDenied,
      ...redlinePayload,
    })
  },

  proposalStarted: ({
    initialSlug,
    finalSlug,
    category,
    redirectUrl,
    fireboltId,
    rdg,
  }) => {
    const payload = {
      fireboltId,
      initialSlug,
      finalSlug,
      category,
      redirectUrl,
      startSourceUrl: getCookie("start_source_url"),
      marketplace: APP_NAME,
      experience: "unlogged",
      rdg,
    }

    return triggerTrackers({
      isTracked: {
        pixel: false,
        ttq: false,
        redline: true,
        ga: true,
      },
      event: "proposalStarted",
      payload,
      blockEvent: true,
    })
  },
}

export default trackEvents
