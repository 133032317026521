const getFbCookies = () => {
	const getSpecificCookie = value => {
	  const cookies = document.cookie ? document.cookie.split('; ') : []
	  return cookies.find(row => row.startsWith(value))
	}

	const fbpCookie = getSpecificCookie('_fbp')
	const fbcCookie = getSpecificCookie('_fbc')
	const fbpId = fbpCookie ? fbpCookie.split('=')[1] : null
	const fbcId = fbcCookie ? fbcCookie.split('=')[1] : null


	return { fbpId, fbcId }
  }

  export default getFbCookies
