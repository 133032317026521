import { GA4_KEY } from "gatsby-env-variables"
import isClient from "@mkt/utils/isClient"

import { generatePixelPayload, pixel } from "./pixel"
import { ttqTrackEvent } from "./ttqEvents"
import { redlineEvent } from "../constants"
import rlTrack from "./utils/rlTrack"

export default function triggerTrackers({
  isTracked = { pixel: true, ga: true, ttq: true, redline: true },
  event,
  payload,
  contentType = "product",
  blockEvent = false,
}) {
  if (isTracked.pixel) pixel(event, payload, contentType)

  if (isTracked.ttq) ttqTrackEvent(event, payload, contentType)

  if (isTracked.ga && isClient() && window.gtag) {
    const separator = "_"
    const eventPayload = generatePixelPayload({
      name: event,
      properties: payload,
      contentType,
      separator,
    })

    gtag("event", event, { event_category: "All", value: 0 })

    if (blockEvent) {
      new Promise((resolve) => {
        const callback = {
          event_callback: function () {
            resolve()
          },
        }
        const blockEventPayload = { ...eventPayload, ...callback }
        gtag("event", event, { send_to: GA4_KEY, ...blockEventPayload })
      })
    } else {
      gtag("event", event, { send_to: GA4_KEY, ...eventPayload })
    }
  }

  if (isTracked.redline) {
    return rlTrack(redlineEvent[event], {
      event: redlineEvent[event],
      ...payload,
    })
  } else {
    return new Promise((res) => res())
  }
}
